:root {
  --main-color: white;
}

@font-face {
  font-family: "HelveticaDisplay";
  src: url("HelveticaNowDisplay.f82c8cfe.otf") format("truetype");
}

@font-face {
  font-family: "HelveticaDisplayBold";
  src: url("HelveticaNowDisplayBold.d50b14a4.otf") format("truetype");
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: var(--main-color);
  transition: background-color 0.5s ease-out;
  font-family: "HelveticaDisplayBold", sans-serif;
}

#logo_wrapper {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in;
  z-index: 100;
  pointer-events: none;
}

#logo_wrapper #logo {
  display: flex;
  width: 80vh;
  max-width: 80vw;
  height: 80vh;
  max-height: 80vw;
  border-radius: 50%;
  cursor: pointer;
  user-select: none;
  background-color: var(--main-color);
  pointer-events: all;
  transition: transform 0.5s ease-in;
}

#logo_wrapper #logo svg {
  user-select: none;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

#logo_wrapper.top_left {
  transform: translate(-50.09%, -50%);
}

@media (orientation: landscape) {
  #logo_wrapper.top_left #logo {
    transform: scale(0.5) rotate(-180deg) translate(0, -40vh);
  }
}

@media (orientation: portrait) {
  #logo_wrapper.top_left #logo {
    transform: scale(0.5) rotate(-180deg) translate(0, -40vw);
  }
}

#pages {
  position: absolute;
  top: 0px;
  width: 100%;
}

#pages a:link,
#pages a:hover,
#pages a:visited,
#pages a:active {
  color: black;
}

#pages .programmWrapper {
  padding-top: 25vh;
}

#pages .programmWrapper .programm {
  position: relative;
  padding: 20px;
  padding-top: 40px;
  padding-left: 25vw;
  font-size: 4vw;
  line-height: 4vw;
  box-shadow: 0 8px 8px 0px rgba(0, 0, 0, 0.2);
  transition: transform 1s ease-out;
  background-color: var(--main-color);
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm {
    font-size: 8vw;
    line-height: 8vw;
    padding-left: 20px;
  }
}

#pages .programmWrapper .programm .content {
  cursor: pointer;
}

#pages .programmWrapper .programm .content ul {
  font-size: unset;
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
  white-space: nowrap;
}

#pages .programmWrapper .programm .content ul li {
  font-size: unset;
  display: inline-flex;
}

#pages .programmWrapper .programm .content ul li:first-child {
  margin-right: 25px;
  min-width: 39px;
}

#pages .programmWrapper .programm .content .short {
  display: block;
  max-height: 0px;
  transition: max-height 0.5s ease-out;
  overflow: hidden;
}

#pages .programmWrapper .programm .content .short.open {
  max-height: 10000px;
}

#pages .programmWrapper .programm .content .short .detail {
  margin-top: 40px;
  padding-bottom: 40px;
  font-family: "HelveticaDisplay", sans-serif;
  font-size: 2vw;
  line-height: 2.5vw;
}

#pages .programmWrapper .programm .content .short .detail b {
  font-family: "HelveticaDisplayBold", sans-serif;
  font-weight: normal;
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm .content .short .detail {
    font-size: 4.5vw;
    line-height: 5.5vw;
  }
}

#pages .programmWrapper .programm .content form input[type=text] {
  border: none;
  height: 2.8vw;
  width: -webkit-fill-available;
  font-size: 2vw;
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm .content form input[type=text] {
    height: 5.5vw;
  }
}

#pages .programmWrapper .programm .content form input[type=submit] {
  border: none;
  height: 5.5vw;
  width: -webkit-fill-available;
  font-size: 2vw;
  background-color: black;
  color: white;
  margin-top: 5.5vw;
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm .content form input[type=submit] {
    font-size: 4vw;
    height: 12vw;
    margin-top: 8vw;
  }
}

#pages .programmWrapper .programm .content form input[type=radio] {
  display: none;
}

#pages .programmWrapper .programm .content form label {
  display: inline-block;
  padding: 0px 0px;
  cursor: pointer;
}

#pages .programmWrapper .programm .content form label span {
  position: relative;
}

#pages .programmWrapper .programm .content form label span:after, #pages .programmWrapper .programm .content form label span:before {
  content: "";
}

#pages .programmWrapper .programm .content form label span:after {
  background: black;
  width: 2.8vw;
  height: 2.8vw;
  position: absolute;
  top: 0.25vw;
  left: 0px;
  transition: 300ms;
  opacity: 0;
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm .content form label span:after {
    width: 5.5vw;
    height: 5.5vw;
    top: 0.5vw;
  }
}

#pages .programmWrapper .programm .content form label span:before {
  width: 2.8vw;
  height: 2.8vw;
  top: 0px;
  left: 0px;
  margin-right: 4px;
  display: inline-block;
  vertical-align: top;
  background-color: white;
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm .content form label span:before {
    width: 5.5vw;
    height: 5.5vw;
  }
}

#pages .programmWrapper .programm .content form label input:checked + span:after {
  opacity: 1;
}

#pages .programmWrapper .programm .moreInfos {
  position: absolute;
  bottom: calc(20px + 2.8vw);
  left: 0px;
  right: 0px;
  min-height: 300px;
  background-color: white;
  color: black;
  display: none;
  padding: 20px;
  font-size: 2vw;
  line-height: 2.8vw;
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm .moreInfos {
    font-size: 4.5vw;
    line-height: 5.5vw;
    bottom: calc(20px + 5.5vw);
  }
}

#pages .programmWrapper .programm .moreInfos p {
  margin: 0px;
  display: none;
}

#pages .programmWrapper .programm .moreInfos p.show {
  display: block;
}

#pages .programmWrapper .programm .moreInfos.active {
  display: block;
}

#pages .programmWrapper .programm .moreInfos span.closer {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  background-color: var(--main-color);
}

#pages .programmWrapper .programm ul.footer {
  position: absolute;
  left: 0px;
  bottom: 0px;
  font-size: 2vw;
  line-height: 2.8vw;
  width: 100%;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  display: flex;
  visibility: hidden;
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm ul.footer {
    font-size: 4.5vw;
    line-height: 5.5vw;
  }
}

#pages .programmWrapper .programm ul.footer li {
  list-style: none;
  display: inline-flex;
  flex-basis: 100%;
  padding: 10px;
  box-sizing: border-box;
  border-left: solid 1px white;
  cursor: pointer;
  user-select: none;
}

#pages .programmWrapper .programm ul.footer li:first-child {
  border: none;
}

#pages .programmWrapper .programm ul.footer li:hover {
  background-color: white;
  color: black;
}

#pages .programmWrapper .programm ul.footer li.active {
  background-color: white;
  color: black;
}

#pages .programmWrapper .programm ul.footer.open {
  visibility: visible;
}

#pages .programmWrapper .programm:last-child {
  margin-bottom: calc(4vw + 20px);
}

@media only screen and (max-width: 600px) {
  #pages .programmWrapper .programm:last-child {
    margin-bottom: calc(8vw + 20px);
  }
}

#pages .season {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 10px;
  background-color: white;
  box-shadow: none;
  padding-left: 25vw;
  font-size: 4vw;
  line-height: 4vw;
}

@media only screen and (max-width: 600px) {
  #pages .season {
    font-size: 8vw;
    line-height: 8vw;
    padding-left: 20px;
  }
}

#pages .season ul {
  color: var(--main-color);
  margin: 0px;
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#pages .season ul li {
  cursor: pointer;
}

#pages .season ul li.active {
  color: black;
}

#pages .season ul li:last-child {
  margin-right: 5px;
}

#bandeli {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: white;
  color: var(--main-color);
  font-size: 4vw;
  line-height: 4vw;
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  #bandeli {
    font-size: 8vw;
    line-height: 8vw;
  }
}

#bandeli .scrolling-text {
  width: max-content;
}
/*# sourceMappingURL=index.4dbbd8fd.css.map */
