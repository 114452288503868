:root {
  --main-color: white;
}
@font-face {
  font-family: "HelveticaDisplay";
  src: url("../static/fonts/HelveticaNowDisplay.otf") format("truetype");
}
@font-face {
  font-family: "HelveticaDisplayBold";
  src: url("../static/fonts/HelveticaNowDisplayBold.otf") format("truetype");
}
html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  background-color: var(--main-color);
  transition: background-color 0.5s ease-out;
  font-family: "HelveticaDisplayBold", sans-serif;
}
#logo_wrapper {
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-in;
  z-index: 100;
  pointer-events: none;
  #logo {
    display: flex;
    width: 80vh;
    max-width: 80vw;
    height: 80vh;
    max-height: 80vw;
    border-radius: 50%;
    cursor: pointer;
    user-select: none;
    background-color: var(--main-color);
    pointer-events: all;
    svg {
      user-select: none;
      pointer-events: none;
      width: 100%;
      height: 100%;
    }
    transition: transform 0.5s ease-in;
  }
  &.top_left {
    transform: translate(-50.09%, -50%);
    #logo {
      @media (orientation: landscape) {
        transform: scale(0.5) rotate(-180deg) translate(0, -40vh);
      }
      @media (orientation: portrait) {
        transform: scale(0.5) rotate(-180deg) translate(0, -40vw);
      }
    }
  }
}

#pages {
  position: absolute;
  //   left: 30%;
  // top: 25vh;
  top: 0px;
  width: 100%;
  // background-color: var(--main-color);

  // transition: transform 1s ease-out;
  a:link,
  a:hover,
  a:visited,
  a:active {
    color: black;
  }
  .programmWrapper {
    padding-top: 25vh;
    .programm {
      position: relative;
      padding: 20px;
      padding-top: 40px;
      padding-left: 25vw;
      font-size: 4vw;
      line-height: 4vw;
      box-shadow: 0 8px 8px 0px rgba(0, 0, 0, 0.2);
      // transform: translateY(-800%);
      transition: transform 1s ease-out;
      background-color: var(--main-color);

      @media only screen and (max-width: 600px) {
        font-size: 8vw;
        line-height: 8vw;
        padding-left: 20px;
      }
      .content {
        cursor: pointer;

        ul {
          font-size: unset;
          display: block;
          list-style: none;
          padding: 0;
          margin: 0;
          white-space: nowrap;
          li {
            font-size: unset;
            display: inline-flex;
            &:first-child {
              margin-right: 25px;
              min-width: 39px;
            }
          }
        }
        .short {
          display: block;
          // padding-top: 40px;
          max-height: 0px;
          transition: max-height 0.5s ease-out;
          overflow: hidden;

          &.open {
            max-height: 10000px;
          }
          .detail {
            margin-top: 40px;
            padding-bottom: 40px;
            font-family: "HelveticaDisplay", sans-serif;
            font-size: 2vw;
            line-height: 2.5vw;
            i {
            }
            b {
              font-family: "HelveticaDisplayBold", sans-serif;
              font-weight: normal;
            }
            @media only screen and (max-width: 600px) {
              font-size: 4.5vw;
              line-height: 5.5vw;
            }
          }
        }

        form {
          input[type="text"] {
            border: none;
            height: 2.8vw;
            // vertical-align: text-bottom;
            // margin-bottom: 0.3vw;
            width: -webkit-fill-available;
            font-size: 2vw;
            @media only screen and (max-width: 600px) {
              height: 5.5vw;
            }
          }
          input[type="submit"] {
            border: none;
            height: 5.5vw;
            width: -webkit-fill-available;
            font-size: 2vw;
            background-color: black;
            color: white;
            margin-top: 5.5vw;
            @media only screen and (max-width: 600px) {
              font-size: 4vw;
              height: 12vw;
              margin-top: 8vw;
            }
          }
          input[type="radio"] {
            display: none;
          }
          label {
            display: inline-block;
            padding: 0px 0px;
            cursor: pointer;
            span {
              position: relative;
              &:after,
              &:before {
                content: "";
              }
              &:after {
                background: black;
                width: 2.8vw;
                height: 2.8vw;
                position: absolute;
                top: 0.25vw;
                left: 0px;
                transition: 300ms;
                opacity: 0;
                @media only screen and (max-width: 600px) {
                  width: 5.5vw;
                  height: 5.5vw;
                  top: 0.5vw;
                }
              }
              &:before {
                // border: 1px solid #222021;
                width: 2.8vw;
                height: 2.8vw;
                top: 0px;
                left: 0px;
                margin-right: 4px;
                display: inline-block;
                vertical-align: top;
                background-color: white;
                @media only screen and (max-width: 600px) {
                  width: 5.5vw;
                  height: 5.5vw;
                }
              }
            }
          }
          label input:checked + span:after {
            opacity: 1;
          }
        }
      }
      .moreInfos {
        position: absolute;
        bottom: calc(20px + 2.8vw);
        left: 0px;
        right: 0px;
        min-height: 300px;
        background-color: white;
        color: black; //var(--main-color);
        display: none;
        padding: 20px;
        font-size: 2vw;
        line-height: 2.8vw;
        @media only screen and (max-width: 600px) {
          font-size: 4.5vw;
          line-height: 5.5vw;
          bottom: calc(20px + 5.5vw);
        }
        p {
          margin: 0px;
          display: none;
          &.show {
            display: block;
          }
        }
        &.active {
          display: block;
        }
        span.closer {
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 10px;
          // border: solid 1px var(--main-color);
          border-radius: 50%;
          cursor: pointer;
          background-color: var(--main-color);
        }
      }
      ul {
        &.footer {
          position: absolute;
          left: 0px;
          bottom: 0px;
          font-size: 2vw;
          line-height: 2.8vw;
          width: 100%;
          margin-block-start: 0px;
          margin-block-end: 0px;
          margin-inline-start: 0px;
          margin-inline-end: 0px;
          padding-inline-start: 0px;
          display: flex;
          visibility: hidden;
          // box-shadow: inset 0 8px 8px 0px rgba(0, 0, 0, 0.2);
          // justify-content: space-evenly;
          @media only screen and (max-width: 600px) {
            font-size: 4.5vw;
            line-height: 5.5vw;
          }
          li {
            list-style: none;
            // width: 33.333333%;
            display: inline-flex;
            flex-basis: 100%;
            padding: 10px;
            // color: white;
            // background-color: black;
            box-sizing: border-box;
            border-left: solid 1px white;
            cursor: pointer;
            user-select: none;
            &:first-child {
              border: none;
            }
            &:hover {
              background-color: white;
              color: black;
            }
            &.active {
              background-color: white;
              color: black;
            }
          }

          &.open {
            visibility: visible;
          }
        }
      }
      &:last-child {
        margin-bottom: calc(4vw + 20px);
        @media only screen and (max-width: 600px) {
          margin-bottom: calc(8vw + 20px);
        }
      }
    }
  }
  .season {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    padding: 10px;
    background-color: white;
    // text-align: right;
    box-shadow: none;
    padding-left: 25vw;
    font-size: 4vw;
    line-height: 4vw;
    @media only screen and (max-width: 600px) {
      font-size: 8vw;
      line-height: 8vw;
      padding-left: 20px;
    }

    ul {
      color: var(--main-color);
      margin: 0px;
      padding: 0px;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      li {
        cursor: pointer;

        &.active {
          color: black;
        }
        &:last-child {
          margin-right: 5px;
        }
      }
    }
  }
  // &.show {
  //   div {
  //     transform: translateY(0);
  //   }
  // }
}

#bandeli {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  // height: 5.5vw;
  background-color: white;
  color: var(--main-color);
  font-size: 4vw;
  line-height: 4vw;
  padding-top: 10px;
  padding-bottom: 10px;
  @media only screen and (max-width: 600px) {
    font-size: 8vw;
    line-height: 8vw;
  }
  .scrolling-text {
    width: max-content;
  }
}
